































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'
import {
  ApiResponse,
  Requisition,
  RequisitionItem,
  Project,
  Contact,
  Fee,
  Document,
  Sale,
  Rate,
  Invoice,
  TimelineItem,
} from '@/interfaces'
import ItemCharges from '@/components/ItemCharges.vue'
import SignedIcon from '@/components/Icons/SignedIcon.vue'
import PdfIcon from '@/components/Icons/PdfIcon.vue'
import ContactForm from '@/components/ContactForm.vue'
import RegroupModal from '@/components/RegroupModal.vue'

export default Vue.extend({
  components: {
    SignedIcon,
    PdfIcon,
    ItemCharges,
    ContactForm,
    RegroupModal,
  },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: () => {
        return {
          id: null,
          requisition: null,
          generatedNumber: null,
          structure: null,
        }
      },
    },
    item: {
      type: Object as () => RequisitionItem,
      required: false,
      default: () => {
        return {
          id: null,
          stockId: null,
          description: '',
          requisition: {} as Requisition,
          requisitionItemFiles: [],
          salesMandate: {} as Document,
          depositVoucher: {} as Document,
          acquereur: null,
          stockStatus: 'new',
          fees: [] as Fee[],
          dateIn: null,
          sale: {} as Sale,
          saleId: null,
          previousOwnerId: null,
          newOwnerId: null,
          ciPresent: true,
          numFormule: null,
          ciDate: null,
          opositionRight: null,
          receipt: null as Invoice | null,
          bill: null as Invoice | null,
          timeline: null as null | TimelineItem[],
          relatedSaleItems: [],
        }
      },
    },
    isDisabled: Boolean,
    saveRequisitionTrigger: Boolean,
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      minPosition: 1,
      busy: false,
      itemData: Object.assign({}, this.item ?? {}) as RequisitionItem,
      rubriques: [],
      stockStatuses,
      vatRates: [] as Rate[],
      experts: [] as Record<string, string | null>[],
      sales: [] as Record<string, string | null>[],
      viewportWidth: 0,
      placesList: [] as Record<string, string>[],
      locationsList: [] as Record<string, string>[],
      acquereurs: [] as Record<string, string | null>[],
      acquereurInfos: {},
      isFormDisabled: false,
      showBusinessInfos: false,
      changeDetected: null as boolean | null,
      showAddContactModal: false,
      newContact: null as Contact | null,
      newContactType: '',
      newContactId: null as string | null,
      resetContactForm: false,
      errorMsg: null as string | null,
      dsvFormError: false,
      stockId: null as string | null,
      documentType: null as string | null,
      popoverVente: false,
      popoverDepot: false,
      popoverBordereau: false,
      popoverDecompte: false,
      isDebiteurSubjectToTax: false,
      storageAreas: [] as Record<string, string>[],
      infoPrefill: null as string | null,
      structureId: null,
      itemNum: null as number | null,
      stockStatus: null as string | null,
      showRegroupModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    acquereurName(): string {
      return this.item.acquereur?.formName ?? ''
    },
    generatedNumber(): string {
      return this.project?.generatedNumber ?? ''
    },
    stockNumber(): string {
      return this.item?.stockId ?? ''
    },
    dateIn(): string {
      return this.item?.dateIn ? this.formatDate(this.item.dateIn, true) : ''
    },
    sellerName(): string {
      let sellerName = ''

      let debiteur = this.project.requisition?.debiteur
      let requerant = this.project.requisition?.requerant
      if (this.item) {
        debiteur = this.item.requisition?.debiteur
        requerant = this.item.requisition?.requerant
      }

      if (debiteur?.id && requerant?.id && debiteur.id === requerant.id) {
        sellerName = `${debiteur.formName}`
      } else {
        if (debiteur) {
          sellerName = `${debiteur.formName}`
        }

        if (requerant) {
          sellerName = `${sellerName} / ${requerant.formName}`
        }
      }

      return sellerName
    },
    gageStatus(): string {
      if (this.itemData.gage == null) {
        return 'Inconnue.'
      } else {
        return this.itemData.gage == true
          ? 'La situation administrative ne fait apparaître aucune irrégularité (<strong>Non gagé</strong>).'
          : 'La situation administrative présente au moins une irrégularité (<strong>Gagé</strong>)'
      }
    },
  },
  watch: {
    activeTab(newVal) {
      if (newVal === 'infos' && !this.item?.id && !this.project?.id) {
        this.loadRequisitionItem()
      }
    },
    project: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.structure) {
          this.vatRates = newVal.structure.taxRate?.rates ?? []
          this.structureId = newVal.structure.id
        }
        if (newVal.requisition && !this.item) {
          // Here we use a proxy variable as we can't rely on the itemData object: it'll create a situation where
          let vatRateValue = '0'
          if (newVal && newVal.requisition?.debiteur?.subjectToTax == true) {
            vatRateValue = '20'
            this.isDebiteurSubjectToTax = true
          }
          const dateNow = new Date()
          this.itemData = {
            ...this.itemData,
            stockStatus: 'entry',
            vatRate: vatRateValue,
            dateIn: dateNow.toDateString(),
          }
        }
      },
    },
    stockId() {
      if (this.changeDetected == false) {
        this.changeDetected = true
      }
    },
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.itemData = Object.assign({}, newVal ?? {})

          // Set stock ID
          this.stockId = newVal.stockId

          // Set Ids for select
          this.itemData = {
            ...this.itemData,
            saleId: newVal.sale?.id?.toString() ?? null,
            acquereurId: newVal.acquereur?.id?.toString() ?? null,
            previousOwnerId: newVal.previousOwner?.id?.toString() ?? null,
            newOwnerId: newVal.newOwner?.id?.toString() ?? null,
            expertId: newVal.expert?.id?.toString() ?? null,
          }

          // Set acquereur Id for select
          this.itemData = { ...this.itemData, saleId: newVal.sale?.id?.toString() ?? null }

          if (newVal.acquereur?.id) {
            this.acquereurs = [
              {
                label: newVal.acquereur.formName ?? '-',
                value: newVal.acquereur.id.toString(),
              },
            ]

            this.acquereurInfos = this.setAcquereurInfos(newVal.acquereur)
          }

          if (newVal.requisition?.debiteur?.subjectToTax) {
            this.isDebiteurSubjectToTax = true
          }

          // Experts
          const expert = newVal.expert ?? null
          if (expert?.id) {
            this.experts = [
              {
                label: expert.formName ?? '-',
                value: expert.id.toString(),
              },
            ]
          }

          // Sale
          if (newVal.sale?.id) {
            this.sales = [
              {
                label: this.getSaleTitle(newVal.sale),
                value: newVal.sale?.id?.toString(),
              },
            ]
          }

          this.changeDetected = null
        }
      },
    },
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
    },
    saveRequisitionTrigger() {
      this.submit()
    },
    itemData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$emit('updateData', newVal)
        this.changeDetected = this.changeDetected !== null
      },
    },
    newContact(newVal) {
      if (newVal !== null) {
        switch (this.newContactType) {
          case 'acquereur':
            this.updateAcquereur(newVal)
            break
        }
        this.newContact = null
        this.newContactType = ''
      }
    },
    sales(newVal) {
      if (this.$route.query.sale) {
        const chosenSale = newVal.find((sale: Record<string, string | null>) => sale.value == this.$route.query.sale)
        if (chosenSale && this.itemData.saleId == null) {
          this.itemData.saleId = chosenSale.value
        }
      }
    },
    rubriques() {
      if (this.$route.query.rubriqueKey) {
        let lastRubriqueItem
        if (Array.isArray(this.$route.query.rubriqueKey)) {
          lastRubriqueItem = this.$route.query.rubriqueKey[this.$route.query.rubriqueKey.length - 1]
        } else {
          lastRubriqueItem = this.$route.query.rubriqueKey
        }

        this.itemData.rubriqueKey = lastRubriqueItem ?? null
      }
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }

    if (this.activeTab === 'infos' && !this.item?.id && !this.project?.id) {
      this.loadRequisitionItem()
    }

    if (this.project) {
      this.vatRates = this.project?.structure?.taxRate?.rates ?? []
    }

    if (this.item) {
      this.itemData = Object.assign({}, this.item)
      this.stockStatus = this.item.stockStatus ?? null
    }

    if (this.$route.query.sale) {
      this.loadSales(true)
    }

    if (this.$route.query.itemNum) {
      this.itemData = { ...this.itemData, itemNumber: this.$route.query.itemNum.toString() ?? '' }
    }

    this.changeDetected = null

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    loadRequisitionItem() {
      this.busy = true
      this.$emit('setDisplayAlert', false)

      this.$api
        .get(`/requisitionitems/${this.$route.params.itemId}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('itemUpdated', apiResponse.data)

          if (apiResponse.data.requisition) {
            this.$emit('projectUpdated', apiResponse.data.requisition.project)
          }
        })
        .catch(() => {
          this.$emit('setDisplayAlert', true)
        })
        .finally(() => {
          this.busy = false

          window.scrollTo({
            top: parseInt(this.$route.query.scrollTo ? this.$route.query.scrollTo.toString() : '0'),
          })
        })
    },
    updateAcquereur(contact: Contact) {
      this.updateContactsList(this.acquereurs, contact)
      this.itemData.acquereurId = null
      this.setAcquereurInfos(contact)
      this.$nextTick(() => {
        this.itemData.acquereurId = contact.id?.toString() ?? null
        this.submit()
      })
    },
    getDocument(type: string) {
      if (this.item) {
        if (type === 'depot') {
          return this.item.depositVoucher
        } else if (type === 'vente') {
          return this.item.salesMandate
        }
      }
    },
    getInvoice(type: string) {
      if (this.item) {
        if (type === 'bordereau') {
          return this.item.receipt
        } else if (type === 'decompte') {
          return this.item.bill
        }
      }
    },
    docIsSigned(type: string) {
      return this.getDocument(type)?.signed
    },
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    resetForm() {
      const dateNow = new Date()
      let vatRateValue = '0'
      if (this.project && this.project.requisition?.debiteur?.subjectToTax == true) {
        vatRateValue = '20'
        this.isDebiteurSubjectToTax = true
      }
      this.itemData = {
        description: null,
        rubriqueKey: this.itemData.rubriqueKey ? this.itemData.rubriqueKey : null,
        estimationLow: null,
        estimationHigh: null,
        endPrice: null,
        stockStatus: 'entry',
        vatRate: vatRateValue,
        notes: null,
        requisitionItemFiles: [],
        dateIn: dateNow.toDateString(),
        dateOut: null,
        infoimmat: '',
        gage: null,
        opositionRight: null,
        numFormule: null,
        ciPresent: null,
        ciDate: null,
        storageArea: null,
        itemNumber: this.itemNum ? this.itemNum.toString() : null,
      }

      this.changeDetected = false
    },
    cancelForm() {
      this.$router.push({
        name: 'requisition_edit',
        params: {
          requisitionId: this.itemData.requisition?.id?.toString() ?? '',
        },
      })
    },
    getFormattedDate(str: string | null): string | null {
      if (str === null) {
        return null
      }

      let date = new Date()
      if (str !== 'now') {
        const timestamp = Date.parse(str)
        date = new Date(timestamp)
      }
      const year = date.getFullYear()
      let month = (date.getMonth() + 1).toString()
      if (month.length < 2) {
        month = `0${month}`
      }
      let day = date.getDate().toString()
      if (day.length < 2) {
        day = `0${day}`
      }

      return `${year}-${month}-${day}`
    },
    submit(createAgain = false, force = false) {
      if (this.changeDetected === true || force === true) {
        this.errorMsg = null
        this.dsvFormError = false

        if (
          (this.itemData.vatRate === '0' && this.isDebiteurSubjectToTax) ||
          (this.itemData.vatRate !== '0' && !this.isDebiteurSubjectToTax)
        ) {
          this.$notify({
            type: 'warning',
            title: 'Attention',
            message:
              "Il y a une incohérence entre l'assujettissement à la TVA de la personne concernée et le taux de TVA de ce lot.",
          })
        }

        let lastRubriqueItem
        if (Array.isArray(this.itemData.rubriqueKey)) {
          lastRubriqueItem = this.itemData.rubriqueKey[this.itemData.rubriqueKey.length - 1]
        } else {
          lastRubriqueItem = this.itemData.rubriqueKey
        }

        const data = {
          description: this.itemData.description,
          rubriqueKey: lastRubriqueItem ?? null,
          estimationLow: this.itemData.estimationLow ? parseFloat(this.itemData.estimationLow) : null,
          estimationHigh: this.itemData.estimationHigh ? parseFloat(this.itemData.estimationHigh) : null,
          startingPrice: this.itemData.startingPrice ? parseFloat(this.itemData.startingPrice) : null,
          endPrice: this.itemData.endPrice ? parseFloat(this.itemData.endPrice) : null,
          reservePrice: this.itemData.reservePrice ? parseFloat(this.itemData.reservePrice) : null,
          reserveNetPrice: this.itemData.reserveNetPrice ? parseFloat(this.itemData.reserveNetPrice) : null,
          stockStatus: this.itemData.stockStatus ?? null,
          stockId: this.stockId ? this.stockId.toString() : null,
          itemNumber: parseInt(this.itemData.itemNumber ?? '') ?? null,
          itemSubNumber: this.itemData.itemSubNumber ?? null,
          notes: this.itemData.notes ?? null,
          highlight: this.itemData.highlight ?? null,
          policeBook: this.itemData.policeBook ?? null,
          adagp: this.itemData.adagp ?? null,
          weaponBook: this.itemData.weaponBook ?? null,
          vehicule: this.itemData.vehicule ?? null,
          plateNumber: this.itemData.plateNumber ?? null,
          vatRate: this.itemData.vatRate ?? null,
          sale: this.itemData.saleId ? this.itemData.saleId : null,
          acquereur: this.itemData.acquereurId ?? null,
          expert: this.itemData.expertId ?? null,
          dateIn: this.itemData.dateIn,
          dateOut: this.itemData.dateOut,
          infoimmat: this.itemData.infoimmat == '' ? null : this.itemData.infoimmat,
          gage: this.itemData.gage ?? null,
          previousOwner: this.itemData.previousOwnerId ?? null,
          newOwner: this.itemData.newOwnerId ?? null,
          ciPresent: this.itemData.ciPresent ?? null,
          ciDate: this.itemData.ciDate ?? null,
          opositionRight: this.itemData.opositionRight ?? null,
          numFormule: this.itemData.numFormule ?? null,
          cessionDate: this.itemData.cessionDate ?? null,
          rgaNumber: this.itemData.rgaNumber ?? null,
          storageArea: this.itemData.storageArea ?? null,
          timeline: this.itemData.timeline ?? null,
        }

        if (this.item) {
          //const oldJewelry = this.itemData.policeBook

          // Edit
          this.busy = true
          this.$api
            .put(`/requisition/${this.itemData.requisition?.id?.toString() ?? ''}/item/${this.item.id}`, data)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              const updatedItem = apiResponse.data.updatedItem
              this.itemData = updatedItem
              this.$emit('itemUpdated', updatedItem)

              //const oldStockStatus = this.stockStatus
              //const newStockStatus = this.itemData.stockStatus

              this.itemNum = updatedItem.sale?.lastItemNum ? updatedItem.sale.lastItemNum + 1 : null

              // géré par API
              // if (oldStockStatus !== newStockStatus && newStockStatus == 'selling') {
              //   window.setTimeout(() => {
              //     this.$notify({
              //       type: 'info',
              //       title: 'Info',
              //       message: 'Le statut du lot est passé « en vente »',
              //     })
              //   }, 700)
              // }

              this.stockStatus = this.itemData.stockStatus ?? null

              this.$notify({
                type: 'success',
                title: 'Succès',
                message: `Lot ${updatedItem.stockId} modifié avec succès !`,
              })

              // géré par API
              // if (updatedItem.policeBook && oldJewelry !== updatedItem.policeBook) {
              //   window.setTimeout(() => {
              //     this.$notify({
              //       type: 'info',
              //       title: 'Info',
              //       message: `Ajouté dans le livre des bijoux et metaux précieux.`,
              //     })
              //   }, 500)
              // }

              if (apiResponse.data?.existingStockId) {
                window.setTimeout(() => {
                  this.$notify({
                    type: 'warning',
                    title: 'Attention',
                    message: `Attention : un lot possédant ce numéro existe déjà.`,
                  })
                }, 500)
              }
            })
            .finally(() => {
              this.busy = false

              if (createAgain == true) {
                this.newItem()
              }
            })
        }
      }
    },
    formatDate(str: string, short = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (short === true) {
          return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(date)
        } else {
          return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
        }
      } else {
        return '-'
      }
    },
    searchAcquereurs(search?: string) {
      this.acquereurs = this.searchContacts(search)
    },
    searchContacts(
      search?: string,
      category = null as string[] | null,
      minLength = 2
    ): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }

        this.infoPrefill = search

        let contacts = [] as Record<string, string | null>[]
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.busy = false
          })

        return contacts
      } else {
        return []
      }
    },
    clearNewContact() {
      this.acquereurs = []
      this.itemData.acquereurId = null
      this.newContactId = null
      this.resetContactForm = !this.resetContactForm
      this.changeDetected = true
    },
    setAcquereurInfos(contact: Contact | null) {
      let address = '-'
      if (contact?.address != null) {
        address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${
          contact?.city ?? ''
        }`
      }

      this.showBusinessInfos = false

      if (contact?.type == 'personne_morale') {
        this.showBusinessInfos = true
      }

      return {
        siret: contact?.siret ?? '-',
        vat: contact?.vat ?? '-',
        fullName: contact?.fullName ?? '-',
        formName: contact?.formName ?? '-',
        address,
        email: contact?.email ?? '-',
        phone: contact?.phone ?? '-',
        mobile: contact?.mobile ?? '-',
        companyName: contact?.companyName ?? '-',
      }
    },
    loadAcquereurInfo(id: string, type: string) {
      if (id === 'add') {
        this.openContactForm(type)
      } else {
        if (id == '') {
          this.acquereurInfos = this.setAcquereurInfos(null)
          return
        }
        this.busy = true
        this.$api
          .get(`/contact/${id}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.acquereurInfos = this.setAcquereurInfos(apiResponse.data)
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    nl2br(str: string): string {
      if (typeof str !== 'undefined') {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      } else {
        return ''
      }
    },
    loadExperts(isVisible: boolean) {
      if (isVisible) this.experts = this.searchContacts('*', ['commissaire_justice', 'expert'])
    },
    loadSales(isVisible: boolean) {
      if (isVisible) {
        this.sales = []

        this.$api.get('/sales?type=simple').then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((sale: Sale) => {
            if (sale.status && sale.status !== 'comptabilise') {
              this.sales.push({
                label: this.getSaleTitle(sale),
                value: sale?.id?.toString() ?? null,
              })
            }
          })
        })
      }
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    openContactForm(type: string, contactId?: string) {
      if (!this.busy) {
        this.showAddContactModal = true
        this.newContactType = type
        this.newContactId = contactId ?? null
      }
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.newContactType = 'acquereur'
      this.newContact = contact
      this.changeDetected = true
    },
    updateContactsList(contactsList: Record<string, string | null>[], contact: Contact) {
      contactsList.length = 0
      contactsList.push({
        label: contact.formName ?? '-',
        value: contact.id?.toString() ?? null,
      })
    },
    confirmCheck(type: string) {
      switch (type) {
        case 'weapon':
          if (this.itemData.weaponBook === true) {
            this.$confirm('En cochant cette case vous ferez figurer le lot dans le livre des armes.', {
              title: 'Confirmation',
              confirmButtonText: 'Confirmer',
              cancelButtonText: 'Annuler',
              type: 'warning',
            })
              .then(() => {
                this.itemData.weaponBook = true
              })
              .catch(() => {
                this.itemData.weaponBook = false
              })
          } else {
            this.itemData.weaponBook = false
          }
          break

        case 'jewelry':
          if (this.itemData.policeBook === true) {
            this.$confirm(
              'En cochant cette case vous ferez figurer le lot dans le livre des bijoux et métaux précieux.',
              {
                title: 'Confirmation',
                confirmButtonText: 'Confirmer',
                cancelButtonText: 'Annuler',
                type: 'warning',
              }
            )
              .then(() => {
                this.itemData.policeBook = true
              })
              .catch(() => {
                this.itemData.policeBook = false
              })
          } else {
            this.itemData.policeBook = false
          }
      }
    },
    searchExistingStorageArea(queryString: string, cb: CallableFunction) {
      let results = [] as Record<string, string>[]
      const storageAreas = [] as Record<string, string>[]
      this.$api
        .get(`/storage-areas`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((storageArea: string) => {
            storageAreas.push({ value: storageArea })
          })
          results = queryString ? storageAreas.filter(this.createFilter(queryString)) : storageAreas
        })
        .finally(() => {
          cb(results)
        })
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    downloadDocument() {
      let invoiceId = 0
      if (this.documentType === 'bordereau' || this.documentType === 'decompte') {
        if (this.documentType === 'bordereau') {
          invoiceId = this.item.receipt?.id ?? 0
        } else {
          invoiceId = this.item.bill?.id ?? 0
        }

        const loading = this.$loading({
          target: '#documentAction',
          text: 'Chargement des données...',
        })

        this.$api
          .post(`/invoice/${invoiceId}/generate/no-send`)
          .then(() => {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/invoice/${invoiceId}/download?contentd=inline`

            window.open(documentUrl, '_blank')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            loading.close()
            this.closeAllPopover()
          })
      } else if (this.documentType === 'depot' || this.documentType === 'vente') {
        let document = null
        if (this.documentType === 'depot') {
          document = this.item.depositVoucher
        } else {
          document = this.item.salesMandate
        }
        let documentUrl = ''
        if (document?.type === 'pdf') {
          documentUrl = `${process.env.VUE_APP_API_URL}/document/${document?.accessToken}/download?contentd=inline`
        } else {
          documentUrl = `${process.env.VUE_APP_API_URL}/document/${document?.accessToken}/download`
        }
        window.open(documentUrl, '_blank')
        this.closeAllPopover()
      }
    },
    detachDocument() {
      const attributeTypes = [
        {
          type: 'bordereau',
          attribute: 'receipt',
        },
        {
          type: 'decompte',
          attribute: 'bill',
        },
        {
          type: 'depot',
          attribute: 'depositVoucher',
        },
        {
          type: 'vente',
          attribute: 'salesMandate',
        },
      ]

      const data = attributeTypes.find((attributeType) => this.documentType === attributeType.type)

      this.$confirm(
        'Êtes-vous sûr(e) de vouloir détacher ce lot de ce document ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .put(`/requisitionitems/${this.item.id}/detach`, {
            attribute: data?.attribute,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.itemData = apiResponse.data
            this.$emit('itemUpdated', apiResponse.data)

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
            this.closeAllPopover()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    setDocType(docType: string) {
      this.documentType = docType
    },
    closeAllPopover() {
      this.popoverVente = false
      this.popoverDepot = false
      this.popoverBordereau = false
      this.popoverDecompte = false
    },
    closeContactModal() {
      this.showAddContactModal = false
      this.$set(this.itemData, 'acquereurId', null)
      this.infoPrefill = null
    },
    getStockStatus(status: string) {
      let str = '-'

      this.stockStatuses.forEach((stockStatus) => {
        if (stockStatus.value === status) {
          str = stockStatus.label
        }
      })

      return str
    },
    isStatusPresent(timeline?: TimelineItem[], str?: string) {
      let found = false
      if (timeline) {
        timeline.forEach((item) => {
          if (item.content === str) {
            found = true
          }
        })
      }

      return found
    },
    filterCategory(node: Record<string, string>, keyword: string) {
      return node.label.toLowerCase().includes(keyword.toLowerCase())
    },
    openRegroupModal() {
      if (this.itemData.stockStatus === 'regrouped') {
        this.showRegroupModal = true
      }
    },
    updateItem(item: RequisitionItem) {
      this.itemData = item
      this.$emit('itemUpdated', item)
    },
    triggerShowRegroupModal(value: boolean) {
      this.showRegroupModal = value
    },
    newItem() {
      const data = {} as Record<string, string>

      if (this.itemData?.id) {
        data.itemId = this.itemData.id.toString()
      }

      if (this.itemData.requisition?.id && this.itemData.requisition.id !== null) {
        this.$api.post(`/requisition/${this.itemData.requisition.id?.toString()}/newitem`, data).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$router.replace({
            name: 'requisition_item_edit',
            params: {
              itemId: apiResponse.data.id.toString(),
            },
          })
        })
      }
    },
  },
})
