













































import { ApiResponse, CarDefect, ItemFile, RequisitionItem } from '@/interfaces'
import Vue from 'vue'
import CarDisplayInModal from '@/components/CarDisplayInModal.vue'

interface CarPart {
  key: string
  label: string
}

export default Vue.extend({
  components: { CarDisplayInModal },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: true,
    },
    defect: {
      type: Object as () => CarDefect,
      required: true,
    },
    selectedPart: {
      type: Object as () => CarPart,
      required: true,
    },
    display: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      addedMediaFile: null as null | ItemFile,
      defectPart: '',
      defectType: 0,
      defectMediafile: null as null | ItemFile,
    }
  },
  watch: {
    defect(newVal) {
      this.defectPart = newVal.part
      this.defectType = newVal.type
      this.defectMediafile = newVal.mediafile
    },
  },
  mounted() {
    this.defectPart = this.defect.part
    this.defectType = this.defect.type
    this.defectMediafile = this.defect.mediafile
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    submit(closeAndUpdate = true) {
      if (this.item) {
        const item = {
          id: this.item.id,
          vehicle: {
            defects: [] as CarDefect[],
          },
        }

        let found = false
        this.item.vehicle?.defects?.forEach((defect) => {
          if (defect.part === this.defectPart) {
            found = true
            item.vehicle.defects.push({
              part: this.defectPart,
              type: this.defectType,
              mediafile: this.defectMediafile,
            })
          } else {
            item.vehicle.defects.push(defect)
          }
        })

        // If no defect was found, this is a new one
        if (found == false) {
          item.vehicle.defects.push({
            part: this.defectPart,
            type: this.defectType,
            mediafile: this.defectMediafile,
          })
        }

        this.busy = true

        this.$api
          .put(`/requisition/${this.item.requisition?.id ?? null}/item/${this.item.id}`, item)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.addedMediaFile = null

            if (closeAndUpdate) {
              this.$emit('closeAndUpdate', apiResponse.data)
            } else {
              this.$emit('updateItem', apiResponse.data)
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    submitPhoto() {
      document.getElementById(this.display + 'defectFileUpload')?.click()
    },
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }
      this.uploadFile(inputFiles[0])
    },
    uploadFile(file: File) {
      if (!file) return
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener(
        'load',
        () => {
          let dataurl = ''
          if (reader.result) {
            const canvas = document.createElement('canvas')
            // Creating a canvas to resize later
            let img = document.createElement('img')
            let ctx = canvas.getContext('2d')
            img.src = reader.result?.toString() ?? ''

            img.onload = () => {
              canvas.width = img.width
              canvas.height = img.height
              ctx?.drawImage(img, 0, 0, img.width, img.height)

              let MAX_WIDTH = 2048
              let MAX_HEIGHT = 2048
              let width = img.width
              let height = img.height

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
                }
              }

              // Clearing canvas...
              ctx?.clearRect(0, 0, canvas.width, canvas.height)

              // ...to refill it with the resized picture
              ctx = canvas.getContext('2d')
              canvas.width = width
              canvas.height = height
              ctx?.drawImage(img, 0, 0, width, height)

              dataurl = canvas.toDataURL('image/jpeg', 0.75).toString().split(',')[1]
              this.sendData(file, dataurl)
            }
          }
        },
        false
      )
    },
    sendData(file: File, dataurl: string) {
      const data = {
        filename: file.name,
        data: dataurl,
      }

      this.busy = true
      this.defectMediafile = null

      this.$api
        .post(`/requisitionitem/${this.item.id}/file`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Fichier ajouté avec succès !',
          })

          this.addedMediaFile = apiResponse.data

          this.defectMediafile = apiResponse.data

          this.submit(false)
        })
        .catch((error) => {
          if (error.response?.status === 413) {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Erreur : le fichier que vous souhaitez téléverser est trop lourd.',
            })
          } else {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                error.response?.data?.message ??
                'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    getImageUrl(itemFile: ItemFile) {
      if (itemFile !== null) {
        return itemFile.url
      }
    },
    unlinkImage() {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir dissocier cette photo du véhicule. Attention, cette opération est irréversible. Vous pourrez toujours retrouver la photo dans la gestion des médias du lot.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.defectMediafile = null
        this.submit(false)
      })
    },
  },
})
